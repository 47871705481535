@import url(https://rsms.me/inter/inter.css);
.main-view {
  min-height: calc(100vh - 164px);
}

.App {
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

#snackbar {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.25s, opacity 0.25s linear;

  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50vw;
  bottom: 30px;
  font-size: 0.875rem;
}

#snackbar.show {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.125s linear;
}

#snackbar.error {
  background-color: #f44336;
}

.jsoneditor.jsoneditor-mode-tree {
  border-color: #ccc;
}

.jsoneditor > .jsoneditor-menu {
  background-color: #ccc;
  border: none;
}

html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f1f1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
  border-radius: 10px;
  background-color: #eeeeee;
  overflow-x: auto;
}

::-webkit-scrollbar
{
  width: 6px;
  height: 6px;
  background-color: #eeeeee;
  overflow-x: auto;
}

::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.2);
  background-color: #cccccc;
  overflow-x: auto;
}

