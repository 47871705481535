@import "~bootstrap-utilities";

.main-view {
  min-height: calc(100vh - 164px);
}

.App {
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

#snackbar {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.25s, opacity 0.25s linear;

  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50vw;
  bottom: 30px;
  font-size: 0.875rem;
}

#snackbar.show {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.125s linear;
}

#snackbar.error {
  background-color: #f44336;
}

.jsoneditor.jsoneditor-mode-tree {
  border-color: #ccc;
}

.jsoneditor > .jsoneditor-menu {
  background-color: #ccc;
  border: none;
}
